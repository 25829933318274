<template>
  <!-- <a-card
    :editable="false"
  >
    <a-select
      v-if="user.role === 'admin'"
      :manualDoc="manualDoc"
      field="customer"
      label="Customer"
      options="customers"
      placeholder="Please select a customer"
    />

    <div
      v-if="selectedCustomer"
    />
      <a-select
        v-if="selectedCustomer.operators && selectedCustomer.operators.length"
        :manualDoc="manualDoc"
        field="operator"
        label="Vehicle Type"
        :options="selectedCustomer.operators"
        optionLabel="name"
        null-option
        placeholder="Please select an operator"
      />

      <a-select
        :manualDoc="manualDoc"
        field="vehicleType"
        label="Vehicle Type"
        :options="selectedCustomer.vehicleTypes"
        optionLabel="type"
        placeholder="Please select vehicle type"
      />

      <a-input
        :manualDoc="manualDoc"
        field="number"
        label="Number"
        placeholder="Please fill in vehicle number"
      />

      <button
        class="button is-primary"
        @click="sendRequest"
      >
        Done
      </button>
    </div>
    </a-card> -->
</template>

<script>

export default {
  /*computed: {
    selectedCustomer () {
      return this.$store.getters['customers/data'][this.doc.customer.id]
    }
  },
  methods: {
    sendRequest () {
      const otherCustomerVehicles = _.filter(this.$store.getters['vehicles/items'], vehicle => vehicle.customer.id === this.doc.customer.id && vehicle.id !== this.doc.id)
      if (_.find(otherCustomerVehicles, { number: this.doc.number })) {
        this.$buefy.dialog.alert(`Vehicle number ${this.doc.number} already exists!`)
      } else {
        this.$emit('close', this.doc)
      }
    }
  }*/
}
</script>
